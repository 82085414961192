import { H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";
import { headerList } from "../../utils/constant";
import { useState } from "react";
import { validateArray } from "../../utils/validate";
import PdfDocumentRdr from "../../containers/ManegeProcedure/PdfDocumentRdr";
import PdfTemplate from "../../containers/ManegeProcedure/PdfTemplate";


const ManageLots = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function createLot(reload) {
    openModal(<FormLot reload={reload} onClose={onClose} />);
  }

  function editLot(lot, reload) {
    if(lot.status!=="Creado"){
      toast.warning("No se puede editar el lote por que ya fue entregado");
      return;
    }
    openModal(<FormEditLot lot={lot} reload={reload} onClose={onClose} />);
  }

  function changeState(lot, reload) {

    let status = lot.status === "CorregidoPre" ? "Corregido" : "Entregado"

    const formData = {
      status: status,
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere entregar el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={ formData }
        url={`/lot`}
        texBtn="Confirmar"
        handleSuccess={reload}
      />
    );
  }

  function deleteLot(lot, reload) {
    // if(lot.status!=="Creado"){
    //   toast.warning("No se puede eliminar el lote por que ya fue entregado");
    //   return;
    // }
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el lote?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Lot/${lot.id}`}
        texBtn="Eliminar"
        handleSuccess={reload}
      />
    );
  }

  function handleDocumentRdrPdf(lot) {
    openDialog(<PdfDocumentRdr lot={lot} />);
  }

  function editLotObs(lot, reload) {
    // if(lot.status!=="Creado"){
    //   toast.warning("No tiene los permisos para ver el lote");
    //   return;
    // }
    openModal(<FormEditObs lot={lot} reload={reload} onClose={onClose} />);
  }

  return (
    <>
      <CallServiceTable
        addUrl="order=Creado-ObsArchivo-CorregidoPre-Entregado-Recibido-Observado-Concluido"
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createLot(reload)}>
              Crear Lote <i className="fa-solid fa-plus"></i>
            </Button>
          );
        }}
        urlApi="/lot/pag"
        filters={[
          {
            name: "Status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Creado",
                name: "Creado",
              },
              {
                label: "Entregado",
                name: "Entregado",
              },
              {
                label: "Recibido",
                name: "Recibido",
              },
              {
                label: "Observado",
                name: "Observado",
              },
              {
                label: "Concluido",
                name: "Concluido",
              },
              {
                label: "Observado en Archivo",
                name: "ObsArchivo",
              },
              {
                label: "Corregido",
                name: "CorregidoPre",
              },
            ],
          },
        ]}
        header={[
          {
            label: "Nro de lote",
            name: "num",
            filter: true,
          },
          {
            label: "Fecha de Creación",
            name: "creationDate",
            type: "date",
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {

              // const baground = status==='Entregado' 
              //                 ? 'bg-green-600' 
              //                 : status==='Observado'
              //                 ? 'bg-orange-600'
              //                 : status==='Concluido'
              //                 ? 'bg-gray-500'
              //                 : status==='Recibido'
              //                 ? 'bg-yellow-500'
              //                 : status==='ObsArchivo'
              //                 ? 'bg-red-600'
              //                 : 'bg-blue-800'

              const baground = status==='Creado'
                                ? 'bg-blue-800' 
                                : status==='ObsArchivo'
                                  ? 'bg-red-600'
                                  : status==='CorregidoPre'
                                    ? 'bg-teal-600'
                                    : 'bg-green-600'

              const state = status==='Creado'
                            ? 'Creado' 
                            : status==='ObsArchivo'
                              ? 'Observado'
                              : status==='CorregidoPre'
                                ? 'Corregido'
                                : 'Entregado'
              
              return (
                  <span className={`block w-fit px-2 py-1 rounded-full text-white ${ baground }`}>
                    { state }
                  </span>
              );
            },
          },
          {
            label: "Nombre del Usuario",
            name: "nameUser",
          },
          {
            label: "Apellido del Usuario",
            name: "fatherLastNameUser",
          },
          {
            name: "lotProcedures",
            label: "Nro. Interno",
            // filter: true,
            type: "custom",
            builder: (data) => {
              return (
                <div className="">
                  {data && validateArray(data)
                    ? data.map((item) => (
                        <div key={item.id} className="flex flex-wrap">
                          <p className="border-2 border-gray-500 rounded-md px-1">
                            {item.nroInterno || "---"}
                          </p>
                        </div>
                      ))
                    : null}
                </div>
              );
            },
          },
          {
            name: "id",
            label: "Entregar",
            type: "custom",
            builder: (_, __, lot, call) => {
              return (
                <button 
                  className={`block p-2 text-white rounded-lg ${lot.status==="Creado" || lot.status==="CorregidoPre" ? "bg-green-600 hover:bg-green-700" : "cursor-default bg-gray-300"}`}
                  onClick={()=>changeState(lot, call)}
                  disabled={ lot.status!=="Creado" && lot.status!=="CorregidoPre" }
                >{ lot.status==="Creado" || lot.status==="CorregidoPre"  ? "Entregar" : "Entregado" }</button>
              );
            },
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Revisar Carpetas",
                icon: "fas fa-eye",
                action: editLotObs,
                color: "text-[#585858]",
              },
              {
                label: "Editar",
                icon: "fas fa-edit",
                action: editLot,
                color: "text-[#1d4ed8]",
              },
              {
                label: "Eliminar",
                icon: "fas fa-trash",
                action: deleteLot,
                color: "text-red-500",
              },
              {
                label: "Generar RDR-003",
                icon: "fas fa-file-pdf",
                action: handleDocumentRdrPdf,
                color: "text-[#FF8257]",
              },
            ],
            
          },
          
        ]}
      />
    </>
  );
};

const FormEditObs = (props) => {
  const { openDialog, dialogClose } = useDialog();

  const { lot, onClose, reload } = props;

  const { data, call } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });
  
  const [state, setState] = useState(lot.status)

  if(state==="verificar"){
    changeStateRecibido();
  }

  async function changeStateRecibido() {

    if(data?.some(item => item.status === 'Retornado')) return;

    const formData = {
      status: "CorregidoPre",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };
    
    await requestAuth("put", `/lot`, formData)
      .then((res) => {
        reload();
        // toast.success("Lote recibido");
      })
      .catch(() => {
        toast.error("Error al cambiar estado");
      });
    
  }

  function addObservation(procedure) {
    openDialog(
      <InputObservation dialogClose={ dialogClose } procedure={ procedure } reload={ reload } call={call} state={state} setState={setState}/>
    );
  }

  function removeObservation(procedure) {


    let formData = {
      id: procedure.id,
      obs2: procedure.obs2?.trim().length===0 ? null : procedure.obs2,
      obs: procedure.obs,
      status: "Corregido",
      num: procedure.num,
      lotId: procedure.lotId,
      procedureId: procedure.procedureId
    };

    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere quitar la observación?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/lot/loteProcedure`}
        texBtn="Quitar"
        handleSuccess={refresh}
        data={formData}
      />
    );

  }

  function refresh(){
    reload()
    call()
    setState("verificar")
    call()
  }

  function handleTemplatePdf(procedure, lot) {
    openDialog(<PdfTemplate procedure={procedure} lot={lot} />);
  }

  return (
    <>
      <div className="flex gap-5 justify-center">
        <h2 className="text-2xl font-bold">Lista de Lotes</h2>
      </div>
      <div className="my-10 mx-5">
        {data?.map((procedure) => (
          
              lot.status!=="ObsArchivo" && lot.status!=="CorregidoPre"
              ? procedure.obs2 !== null 

                ? <div key={procedure.id} className="bg-white rounded-md px-5 py-2 mb-2">
                    <div                  
                      className="flex gap-2 justify-between items-center"
                      >
                      <div>
                        <p>{procedure.nroInterno}</p>
                        <p>{procedure.companyRazonSocial || "---"}</p>
                      </div>
                      
                      <div>
                        <p className="text-red-500 text-center text-sm">Observaciónes</p>
                        <p className="text-red-500 text-center text-sm">Internas</p>
                        <div className="flex justify-center gap-1">   
                                                
                          <button onClick={() => addObservation(procedure)} className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-pencil text-white mr-2"></i>
                            Editar
                          </button>
                          {/* <button onClick={() => handleTemplatePdf(procedure)} className="block bg-[#FF8257] hover:bg-[#FF8257] text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-file-pdf text-white mr-2"></i>
                            Imprimir
                          </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="p-5 bg-orange-300 border border-orange-600 rounded-lg mt-5">
                      <p>{procedure.obs2}</p>
                    </div>
                  
                  </div>
                : <div
                    key={procedure.id}
                    className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between items-center"
                  >
                    <div>
                      <p>{procedure.nroInterno}</p>
                      <p>{procedure.companyRazonSocial || "---"}</p>
                    </div>
                    <div>
                        <p className="text-green-500 text-center text-sm">Sin observaciones</p>
                        <p className="text-green-500 text-center text-sm">Internas</p>
                        <button onClick={() => addObservation(procedure)} className={`block bg-lime-500 text-white text-sm rounded-full px-2 py-1 hover:bg-lime-400`}>
                          <i className="fas fa-pen text-white mr-2"></i>
                          Añadir obsevación
                        </button>
                      </div>
                  </div>

              : procedure.status === "Corregido"
                ? <div key={procedure.id} className="bg-white rounded-md px-5 py-2 mb-2">
                  
                    <div                  
                      className="flex gap-2 justify-between items-center"
                      >
                      <div>
                        <p>N° {procedure.num}</p>
                        <p>{procedure.nroInterno}</p>
                        <p>{procedure.companyRazonSocial || "---"}</p>
                      </div>
                      
                      <div>                     
                        <p className="text-teal-700 text-center font-bold">Corregido</p>
                        <div className="flex gap-1">
                          
                          <button onClick={() => handleTemplatePdf(procedure, lot)} className="block bg-teal-600 hover:bg-teal-500 text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-file-pdf text-white mr-2"></i>
                            Imprimir
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-5 bg-teal-100 border border-teal-300 rounded-lg mt-5">
                      <p>{procedure.obs}</p>
                    </div>
                  
                  </div>
                : <div key={procedure.id} className="bg-white rounded-md px-5 py-2 mb-2">
                  
                    <div                  
                      className="flex gap-2 justify-between items-center"
                      >
                      <div>
                        <p>N° {procedure.num}</p>
                        <p>{procedure.nroInterno}</p>
                        <p>{procedure.companyRazonSocial || "---"}</p>
                      </div>
                      
                      <div>                     
                        <p className="text-red-500 text-center font-bold">Observado</p>
                        <div className="flex gap-1">
                          <button  ton onClick={() => removeObservation(procedure)} className="block bg-teal-600 hover:bg-teal-500 text-white text-sm rounded-full px-2 py-1">
                            {/* <i className="fas fa-pencil text-white mr-2"></i> */}
                            <i className="fa-solid fa-check text-white mr-2"></i>
                            Corregir
                          </button>
                          <button onClick={() => handleTemplatePdf(procedure, lot)} className="block bg-orange-600 hover:bg-orange-500 text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-file-pdf text-white mr-2"></i>
                            Imprimir
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-5 bg-red-300 border border-red-600 rounded-lg mt-5">
                      <p>{procedure.obs}</p>
                    </div>
                  
                  </div>
          
        ))}
      </div>

      <Button className="py-2 block mx-auto" onClick={() => onClose()}>
        Cerrar
      </Button>
    </>
  );
};

const InputObservation = ({ dialogClose, procedure, reload, call }) => {

  const [inputValue, setInputValue] = useState(procedure.obs2 || "");
  // const [inputValue, setInputValue] = useState(procedure.obs!==null ? procedure.obs || "" : procedure.obs2 || "");

  const inputChange = ( {target} ) => {
    setInputValue(target.value);
  }

  async function addObservation() {

  let formData={}

    if(procedure.obs!==null){
      formData = {
        id: procedure.id,
        obs2: procedure.obs2?.trim().length===0 ? null : procedure.obs2,
        obs: inputValue.trim().length===0 ? null : inputValue.trim(),
        status: procedure.status,
        num: procedure.num,
        lotId: procedure.lotId,
        procedureId: procedure.procedureId
      };
    }else{
      formData = {
        id: procedure.id,
        obs: procedure.obs?.trim().length===0 ? null : procedure.obs,
        obs2: inputValue.trim().length===0 ? null : inputValue.trim(),
        status: procedure.status,
        num: procedure.num,
        lotId: procedure.lotId,
        procedureId: procedure.procedureId
      };
    }

    
    let msg="Se guardo la observación";

    if(inputValue.trim().length === 0) {
      msg="Se quito la observación"
    }
    

    await requestAuth("put", `/lot/loteProcedure`, formData)
      .then((res) => {
        dialogClose();
        reload();
        toast.success(msg);
        call();   
      })
      .catch(() => {
        toast.error("Se produjo un error al añadir observación");
      });

    
  }

  return (
    <>
      <h4 className="text-center text-xl font-bold">Observaciones</h4>
      <input 
        type="text"
        className="w-full bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 my-3" 
        placeholder="Sin observaciones"
        value={ inputValue }
        onChange={ inputChange }
      />
      <div className="w-96 flex gap-4 m-4">        
          <>
            <Button
            onClick={() => dialogClose()}
              className="w-full bg-[#fff] text-[#E7641D] border-2 border-[#E7641D]"
            >
              Cancelar
            </Button>
            <Button 
              onClick={() => addObservation()}
              className="w-full bg-[#E7641D]">
              Guardar
            </Button>
          </>       
      </div>
    </>
  )
}

const FormEditLot = (props) => {
  const { lot, onClose, reload } = props;
  const { openDialog, dialogClose } = useDialog();
  
  const { data, call } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });
  
  const [procedureSelected, setProcedureSelected] = useState([]);

  function deleteItemLot(id) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres quitar de la lista?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/Lot/loteProcedure/${id}`}
        texBtn="Eliminar"
        handleSuccess={succesDelete}
      />
    );
  }

  function succesDelete() {
    call();
    reload();
  }

  function openLote() {
    openDialog(
      <SelectProcedureDialogEdit
        data={data}
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {

    for(let i=0; i<procedureSelected.length; i++){

      let formData = {
        // obs: null,
        // status: "created",
        num: lot.num,
        lotId: lot.id,
        procedureId: procedureSelected[i].id
      };
  
      await requestAuth("post", "/lot/loteProcedure", formData)
        .then((res) => {
          onClose();
          reload();
          toast.success("Se Añadio con éxito");
        })
        .catch(() => {
          toast.error("Se produjo un error al subir el archivo");
        });
    }
  }

  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Añadir Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="mx-10 mt-10 ">
        {data?.map((procedure) => (
          <div
            key={procedure.id}
            className="bg-slate-300 rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
          >
            <div>
              <p>{procedure.nroInterno}</p>
              <p>{procedure.companyRazonSocial || "---"}</p>
            </div>
            <button onClick={() => deleteItemLot(procedure.id)}>
              <i className="fas fa-trash text-[#ff2d55]"></i>
            </button>
          </div>
        ))}
      </div>
      <div className="mx-10 mb-10 ">
        {procedureSelected.map((procedure) => (
          <div
            key={procedure.id}
            className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
          >
            <div>
              <p>{procedure.internCode}</p>
              <p>{procedure.companyName || "---"}</p>
            </div>
            <button onClick={() => handleDeleteItem(procedure.id)}>
              <i className="fas fa-trash text-[#ff2d55]"></i>
            </button>
          </div>
        ))}
      </div>

      <div className="w-full flex gap-2 px-10">
        <Button className="block w-full bg-[#fff] text-[#FBCE00] border-2 border-[#FBCE00]" onClick={() => onClose()}>
          Cerrar
        </Button>
        <Button className="py-2 block w-full" disabled={ procedureSelected.length === 0 } onClick={() => addLots()}>
          Guardar Cambios
        </Button>
      </div>
    </>
  );
};

const FormLot = (props) => {
  const [procedureSelected, setProcedureSelected] = useState([]);
  const { openDialog } = useDialog();

  const { onClose, reload } = props;

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {
    const formData = {
      procedureIds: procedureSelected.map((p) => p.id),
    };

    await requestAuth("post", "/lot", formData)
      .then((res) => {
        onClose();
        reload();
        toast.success("Se creo con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  function openLote() {
    openDialog(
      <SelectProcedureDialog
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }

  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Seleccionar Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="m-5 ">
        {validateArray(procedureSelected) ? (
          procedureSelected.map((procedure) => (
            <div
              key={procedure.id}
              className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
            >
              <div>
                <p>{procedure.internCode}</p>
                <p>{procedure.companyName}</p>
              </div>
              <button onClick={() => handleDeleteItem(procedure.id)}>
                <i className="fas fa-trash text-[#ff2d55]"></i>
              </button>
            </div>
          ))
        ) : (
          <p className="text-center font-semibold text-red-400">
            No se selecciono ninguna carpeta
          </p>
        )}
      </div>
      <Button className="py-2 w-full" disabled={ procedureSelected.length === 0 } onClick={() => addLots()}>
        Crear Lote
      </Button>
    </>
  );
};

const SelectProcedureDialog = ({ procedureSelected, setProcedureSelected }) => {

  const [proceduresList, setProceduresList] = useState(procedureSelected)


  function handleAdd(procedure, reload) {
    if (proceduresList.find((p) => p.id === procedure.id)){
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure])
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.internCode || item.nroInterno}
          </span>
        ))}
      </H3>
      <CallServiceTable
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "Year",
            default: "Todas las gestiones",
            options: [
              {
                label: "2024",
                name: "2024",
                initial: true,
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
              {
                label: "2020",
                name: "2020",
              },
              {
                label: "2019",
                name: "2019",
              },
              {
                label: "2018",
                name: "2018",
              },
              {
                label: "2017",
                name: "2017",
              },
              {
                label: "2016",
                name: "2016",
              },
              {
                label: "2015",
                name: "2015",
              },
            ],
          },
        ]}
        urlApi="/procedure/openprocedures"
        addFilters={[
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
          {
            name: "númeroDePedido",
            label: "Nro. de Pedido",
            filter: true,
          },
        ]}
        header={[
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

const SelectProcedureDialogEdit = ({ data, procedureSelected, setProcedureSelected  }) => {

  const [proceduresList, setProceduresList] = useState(procedureSelected)

  function handleAdd(procedure, reload) {
    if (proceduresList.find((p) => p.id === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    if (data.find((p) => p.procedureId === procedure.id)) {
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure])
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {data.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.nroInterno}
          </span>
        ))}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-400 mx-1 px-2 rounded-lg">
            {item.internCode}
          </span>
        ))}
      </H3>
      <CallServiceTable
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "companyId",
            url: "/company/getallcompanies",
            default: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "Year",
            default: "Todas las gestiones",
            options: [
              {
                label: "2024",
                name: "2024",
                initial: true,
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
              {
                label: "2020",
                name: "2020",
              },
              {
                label: "2019",
                name: "2019",
              },
              {
                label: "2018",
                name: "2018",
              },
              {
                label: "2017",
                name: "2017",
              },
              {
                label: "2016",
                name: "2016",
              },
              {
                label: "2015",
                name: "2015",
              },
            ],
          },
        ]}
        urlApi="/procedure/openprocedures"
        addFilters={[
          {
            name: "internCode",
            label: "Nro. interno",
            filter: true,
          },
          {
            name: "dimNumber",
            label: "Nro. DIM/DEX",
            filter: true,
          },
          {
            name: "númeroDePedido",
            label: "Nro. de Pedido",
            filter: true,
          },
        ]}
        header={[
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default ManageLots;
